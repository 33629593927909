:root {
    --font-family-main: 'SF Pro Text', arial, arial, sans-serif;
    --font-family-main-and-sticker:  Roboto, -apple-system, "apple color emoji", 'SF Pro Text', arial, Apple Color Emoji, arial, sans-serif;
    --font-family-secondary: 'SF Pro Display Bold', arial, sans-serif;
    --font-size-xs: 12px;
    --font-line-xs: 16px;
    --font-xs: var(--font-size-xs) / var(--font-line-xs) var(--font-family-main);
    --font-size-sm: 14px;
    --font-line-sm: 20px;
    --font-sm: var(--font-size-sm) / var(--font-line-sm) var(--font-family-main);
    --font-size-md: 16px;
    --font-line-md: 22px;
    --font-md: var(--font-size-md) / var(--font-line-md) var(--font-family-main);
    --font-size-lg: 20px;
    --font-line-lg: 24px;
    --font-lg: var(--font-size-lg) / var(--font-line-lg) var(--font-family-main);
    --font-size-xl: 24px;
    --font-line-xl: 28px;
    --font-xl: var(--font-size-xl) / var(--font-line-xl) var(--font-family-main);

    // Sizes
    --header-height: 64px;
    --header-height-auth: 69px;
    --header-height-mobile: 52px;
    --header-pinned-message: 54px;
    --sidebar-width: 350px;
    --sidebar-laptop-width: 300px;
    --mini-header-height: 60px;
    --add-channel-sidebar-height: 70px;
    --search-channel-mobile-height: 52px;
    --notification-sidebar: 319px;
    --notification-sidebar-mobile: 299px;
    --header-audio-message: 46px;

    // Radius border
    --radius-0-5: 4px;
    --radius-1: 8px;
    --radius-1-5: 12px;
    --radius-2: 16px;
    --radius-3: 24px;
    --radius-4: 32px;
    --radius-5: 40px;

    // Paddings and margins
    --p-0-25: 2px;
    --p-0-5: 4px;
    --p-0-75: 6px;
    --p-1: 8px;
    --p-1-5: 12px;
    --p-2: 16px;
    --p-2-5: 20px;
    --p-3: 24px;
    --p-4: 32px;
    --p-4-5: 36px;
    --p-5: 40px;
    --p-6: 48px;
    --p-6-5: 52px;
    --p-8: 64px;
    --p-10: 80px;
    --p-12: 96px;
    --p-25: 200px;

    // Font weight
    --font-normal: 400;
    --font-medium: 500;
    --font-semibold: 600;
    --font-bold: 700;
}
